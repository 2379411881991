// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-aktivitetar-js": () => import("./../../../src/pages/aktivitetar.js" /* webpackChunkName: "component---src-pages-aktivitetar-js" */),
  "component---src-pages-basecamp-js": () => import("./../../../src/pages/basecamp.js" /* webpackChunkName: "component---src-pages-basecamp-js" */),
  "component---src-pages-galleri-js": () => import("./../../../src/pages/galleri.js" /* webpackChunkName: "component---src-pages-galleri-js" */),
  "component---src-pages-guiding-js": () => import("./../../../src/pages/guiding.js" /* webpackChunkName: "component---src-pages-guiding-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-modal-2-js": () => import("./../../../src/pages/modal2.js" /* webpackChunkName: "component---src-pages-modal-2-js" */),
  "component---src-pages-modal-js": () => import("./../../../src/pages/modal.js" /* webpackChunkName: "component---src-pages-modal-js" */),
  "component---src-pages-nyheitsbrev-js": () => import("./../../../src/pages/nyheitsbrev.js" /* webpackChunkName: "component---src-pages-nyheitsbrev-js" */),
  "component---src-pages-personvernerklering-js": () => import("./../../../src/pages/personvernerklering.js" /* webpackChunkName: "component---src-pages-personvernerklering-js" */),
  "component---src-pages-stisykling-js": () => import("./../../../src/pages/stisykling.js" /* webpackChunkName: "component---src-pages-stisykling-js" */),
  "component---src-pages-topptur-js": () => import("./../../../src/pages/topptur.js" /* webpackChunkName: "component---src-pages-topptur-js" */),
  "component---src-pages-vannsport-hopping-pa-kjosnesfjorden-js": () => import("./../../../src/pages/vannsport/hopping-pa-kjosnesfjorden.js" /* webpackChunkName: "component---src-pages-vannsport-hopping-pa-kjosnesfjorden-js" */),
  "component---src-pages-vannsport-index-js": () => import("./../../../src/pages/vannsport/index.js" /* webpackChunkName: "component---src-pages-vannsport-index-js" */)
}

